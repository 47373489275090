<template>
	<div
		class="s-entity-list-item"
		:class="{
			's-entity-list-item--dragging': dragging,
			's-entity-list-item--hover': hover,
			's-entity-list-item--interaction': enableInteraction,
			'newly-created': entity.newlyCreated,
			'newly-updated': entity.newlyUpdated,
		}"
		:draggable="enableDrag"
		:dropzone="droppable"
		@dragstart="onDragStart"
		@dragend="onDragEnd"
		@dragover="onDragOver"
		@dragenter="onDragEnter"
		@dragleave="onDragLeave"
		@drop="onDrop"
	>
		<v-layout row align-center class="s-entity-list-item__current" @click.stop="toggleChildren">
			<v-icon color="secondary" class="s-entity-list-item__current__icon">
				{{ $scrinz.getIconForEntityType(entity.type) }}
			</v-icon>

			<router-link class="s-entity-list-item__current__link" :to="`/entities/${entity.id}`">
				{{ entity.name }}
			</router-link>

			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn
						v-on="on"
						class="s-entity-list-item__current__add-btn"
						icon
						small
						v-if="enableInteraction && $can('create', 'Entity') && $scrinz.entityCanHaveChildren(entity)"
						@click.stop="openAddEntityDialog"
					>
						<v-icon>add</v-icon>
					</v-btn>
				</template>

				<span>{{ $t("route.entities.newEntityUnder", [entity.name]) }}</span>
			</v-tooltip>

			<v-spacer />

			<div v-if="$scrinz.entityCanHaveChildren(entity) && !isOrganization" class="s-entity-list-item__current__toggle">
				<v-icon class="s-entity-list-item__current__toggle__icon" v-if="!showChildren">expand_more</v-icon>
				<v-icon class="s-entity-list-item__current__toggle__icon" v-if="showChildren">expand_less</v-icon>
			</div>
		</v-layout>

		<div v-if="showChildren">
			<slot :children="{ dragging, droppable: droppable && !dragging }"></slot>
		</div>

		<div class="s-entity-list-item__drag__container">
			<div class="s-entity-list-item__drag__wrapper" ref="s-entity-list-item__drag__wrapper"></div>
		</div>

		<entity-add-dialog v-model="showAddDialog" :parent-entity="entity" />
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import store from "@/store";

import EntityAddDialog from "./AddDialog.vue";

@Component({
	components: { EntityAddDialog },
})
export default class SEntityListItem extends Vue {
	@Prop({ default: true })
	enableDrag!: boolean;

	@Prop({ default: false, type: [Boolean, Number] })
	enableDrop!: boolean | number;

	@Prop({ default: true })
	enableInteraction!: boolean;

	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	dragging = false;
	dragImage!: Element;
	hover: boolean | number = false;
	showChildren: boolean = true;

	showAddDialog: boolean = false;

	get droppable() {
		return this.enableDrop && !this.dragging && this.$scrinz.entityCanHaveChildren(this.entity);
	}

	get isOrganization() {
		return this.entity.type === "Organization";
	}

	mounted() {
		setTimeout(
			() => {
				delete (this.entity as any).newlyCreated;
				delete (this.entity as any).newlyUpdated;
			},
			0, // tslint:disable-line:no-magic-numbers
		);

		// If is top level, or user isn't admin, display children.
		if (!this.entity.parentId || !this.$can("admin")) {
			this.showChildren = true;
		}
	}

	toggleChildren() {
		if (this.entity.type !== "Organization") {
			this.showChildren = !this.showChildren;
		}
	}

	openAddEntityDialog() {
		this.showChildren = true;
		this.showAddDialog = true;
	}

	onDragStart(event: DragEvent) {
		if (!this.enableDrag) return;
		if (!event.dataTransfer) return;

		// Stop from dagging parents.
		event.stopPropagation();

		// Set that this item is being dragged.
		this.dragging = true;

		// Emit id of entity dragging to parent.
		this.$emit("dragging", this.entity.id);

		// Create a better drag image.
		this.dragImage = (this.$el as any).cloneNode(true);
		this.dragImage.classList.add("s-entity-list-item__drag__image");

		// Find children element.
		const children = Object.keys(this.dragImage.children)
			.map((k: any) => this.dragImage.children[k])
			.find((el) => el.classList.contains("children"));

		// Remove children if present.
		if (children) this.dragImage.removeChild(children);

		// Append drag image clone to wrapper.
		(this.$refs["s-entity-list-item__drag__wrapper"] as any).appendChild(this.dragImage);

		// Set the drag image data transfer.
		event.dataTransfer.setDragImage(this.dragImage, 0, 0);

		// Remove the image as soon as possible, to clean up UI.
		setTimeout(() => {
			(this.$refs["s-entity-list-item__drag__wrapper"] as any).removeChild(this.dragImage);
		}, 0);

		// Setup data transfer.
		event.dataTransfer.setData("text", JSON.stringify(this.entity));
	}

	onDragEnd(event: DragEvent) {
		// Should only handle this even.
		event.stopPropagation();

		// Set dragging to false.
		this.dragging = false;

		// Emit no longer dragging.
		this.$emit("dragging", false);

		// Emit was dropped.
		this.$emit("dropped", this.entity);
	}

	onDragOver(event: DragEvent) {
		if (!this.droppable) return;

		// Allow drop.
		event.preventDefault();
		event.stopPropagation();
		this.hover = this.entity.id;
	}

	onDragEnter(event: DragEvent) {
		if (!this.droppable) return;

		event.preventDefault();
		event.stopPropagation();
		this.hover = this.entity.id;
	}

	onDragLeave(event: DragEvent) {
		if (!this.droppable) return;

		event.stopPropagation();
		this.hover = false;
	}

	async onDrop(event: DragEvent) {
		// Ensure this element is droppable.
		// if (!this.isDroppable(entity)) return;
		if (!event.dataTransfer) return;
		if (!this.droppable) return;

		// Only drop once.
		event.stopPropagation();

		// Flag no longer draggin over.
		this.hover = false;

		// Convert data transfer to JSON.
		const data = JSON.parse(event.dataTransfer.getData("text"));

		// Update the parent id on the data.
		data.parentId = this.entity.id;

		// Dispatch to the store.
		await store.dispatch("updateEntity", data);
	}
}
</script>

<style lang="scss">
.s-entity-list-item {
	background: white;

	> .row {
		// margin: 0 -12px;
		margin: 0;
	}

	& + & {
		border-top: 1px solid rgba(0, 0, 0, 0.12);
	}

	&--hover {
		background: lightcoral;
	}

	&--dragging {
		opacity: 0.4;
	}

	&--interaction &__current:hover {
		background: rgba(0, 0, 0, 0.12);
	}

	&__current {
		min-height: 48px;
		position: relative;

		&__icon {
			padding: 8px;
			// margin-left: 1rem;
		}

		&__link {
			line-height: 28px;
			padding: 10px;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		&__add-btn {
			margin: 4px;
		}

		&__toggle {
			height: 100%;
			padding-right: 1rem;

			.v-icon.v-icon {
				position: absolute;
			}
		}

		&__toggle__icon {
			opacity: 0.5;
			position: absolute;
			right: 0.5em;
			top: 0.5em;
		}
	}

	&__drag {
		&__container {
			height: 0;
			z-index: 1000;
			position: absolute;
			top: -2000px;
			left: -10000px;
		}

		&__wrapper {
			position: relative;
			background: white;
			// height: 0;
			// overflow: hidden;
			padding: 0;
			z-index: 1000;
		}

		&__image {
			// position: absolute;
			// top: -2000px;
			// left: -10000px;
			display: inline-block;
			border: 1px solid rgba(0, 0, 0, 0.12);
			border-radius: 2px;
			padding: 0 1rem 0 0.8rem;
			width: auto;
		}

		.s-entity-list-item__current__add-btn {
			display: none;
		}
	}
}
</style>
